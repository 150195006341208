<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>Statistiques</h1>
        </div>
        <div class="k-page-body">
            <div class="k-flex-row">
                <div class="flex-grow-1">
                    Résumé du cumul des statistiques de
                    <span class="text-kalkin-2">toutes les installations</span>,
                    pour les
                    <span class="text-kalkin-2">
                        {{ durationM }} derniers jours
                    </span>
                    <small>
                        ( {{ dateStartM | dateFromISO(true) }} -
                        {{ dateEnd | dateFromISO(true) }} )
                    </small>
                </div>
                <div class="mx-auto">
                    <b-button size="sm" variant="kalkin-2" @click="goDetails()">
                        <b-icon-zoom-in /> Voir le détail des statistiques
                    </b-button>
                </div>
            </div>
            <div class="k-flex-row row-recap">
                <CardTouches
                    id="card-touches-g-30"
                    :project-id="projectId"
                    :installations="installations"
                    :from="dateStartM"
                    :to="dateEnd"
                />
                <CardSessions
                    id="card-sessions-g-30"
                    :project-id="projectId"
                    :installations="installations"
                    :from="dateStartM"
                    :to="dateEnd"
                />
                <CardTopFiches
                    id="card-top-fiches-g-30"
                    :project-id="projectId"
                    :installations="installations"
                    :from="dateStartM"
                    :to="dateEnd"
                />
            </div>

            <h2 class="mt-4">Mes installations</h2>
            <div>
                Les statistiques détaillées sont également disponibles pour
                chacune de vos installations
            </div>

            <GridCardBasic
                class="mt-2"
                :data="installations"
                empty="Aucune installation"
                minCardSize="400px"
                gap="20px"
            >
                <template v-slot="{ data }">
                    <div class="k-card-3">
                        <div class="k-flex-row flex-nowrap">
                            <h5 class="m-0">{{ data.name }}</h5>
                            <div class="k-flex-line" />
                            <b-button
                                class="text-nowrap"
                                size="xs"
                                variant="kalkin-1"
                                @click="goDetails(data.id)"
                            >
                                <b-icon-zoom-in /> Voir le détail
                            </b-button>
                        </div>
                        <div style="flex:1" />
                        <PanelStatsSummary
                            :project-id="projectId"
                            :installations="[data]"
                            :from="dateStartS"
                            :to="dateEnd"
                            :duration="durationS"
                        />
                    </div>
                </template>
            </GridCardBasic>
        </div>
    </div>
</template>

<script>
import CardTouches from "@/components/model/stats/CardTouches";
import CardSessions from "@/components/model/stats/CardSessions";
import CardTopFiches from "@/components/model/stats/CardTopFiches";
import PanelStatsSummary from "@/components/model/stats/PanelStatsSummary";
import GridCardBasic from "@/components/ui/GridCardBasic";
import { getDateFromToday } from "@/tools/dates";

const TIMESPAN_M = 30;
const TIMESPAN_S = 30;
const TIMESPAN_START = 1;

export default {
    components: {
        CardTouches,
        CardSessions,
        CardTopFiches,
        GridCardBasic,
        PanelStatsSummary,
    },

    data: function() {
        return {
            projectId: this.$route.params.projectId,
            configId: this.$route.params.configId,
            installations: [],
            dateStartM: getDateFromToday(TIMESPAN_START + TIMESPAN_M - 1),
            durationM: TIMESPAN_M,
            dateStartS: getDateFromToday(TIMESPAN_START + TIMESPAN_S - 1),
            durationS: TIMESPAN_S,
            dateEnd: getDateFromToday(TIMESPAN_START),
        };
    },

    methods: {
        async fetchInstallations() {
            this.installations = await this.$store.dispatch(
                "application/getInstallations",
                {
                    projectId: this.projectId,
                    configId: this.configId,
                },
            );
        },

        goDetails(installId = "all") {
            this.$router.push({
                name: "StatsDetails",
                params: { installId },
            });
        },
    },

    async beforeMount() {
        await this.fetchInstallations();
    },
};
</script>

<style lang="scss" scoped>
.row-recap {
    margin: 10px 6px 18px 6px;
    align-items: stretch;
    & > div {
        width: 33%;
        min-width: 300px;
    }
}
</style>
